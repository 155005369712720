
import React from 'react'

// Photos 
import poolinstallation1 from '../images/poolinstallation1.jpg';
import poolinstallation2 from '../images/poolinstallation2.png';
import jmpoolinstallation3 from '../images/jmpoolinstallation3.jpg';
import privacyfence1 from '../images/privacyfence1.jpg';
import privacyfence2 from '../images/privacyfence2.jpg';
import poolrefresh1 from '../images/poolrefresh1.jpg';
import poolrefresh2 from '../images/poolrefresh2.jpg';
import poolrefresh3 from '../images/poolrefresh3.png';
import prevwork from '../images/prevwork.jpg';
import prevwork2 from '../images/prevwork2.jpg';
import prevwork3 from '../images/jmprevwork3.jpg';
import prevwork4 from '../images/jmprevwork4.jpg';
import prevwork5 from '../images/jmprevwork5.jpg';
import prevwork6 from '../images/jmprevwork6.jpg';
import JMPoolNew1 from '../images/JMPoolNew1.jpg';
import JMPoolNew2 from '../images/JMPoolNew2.jpg';
import JMPoolNew3 from '../images/JMPoolNew3.jpg';
import JMPoolNew4 from '../images/JMPoolNew4.jpg';
import JMPoolNew5 from '../images/JMPoolNew5.jpg';
import inGround10 from '../images/InGround10.jpg';

const photos = [
    JMPoolNew1,
    JMPoolNew3,
    JMPoolNew4,
    JMPoolNew5,
    prevwork2,
    prevwork3,
    prevwork4,
    prevwork5,
    prevwork6,
    prevwork,
    poolrefresh1,
    poolrefresh2,
    poolrefresh3,
    poolinstallation1,
    poolinstallation2,
    privacyfence1,
    privacyfence2,
    jmpoolinstallation3,
    JMPoolNew2,
    inGround10
]

const Gallery = () => {

    return (
        <div className="page gallery">
            <h1 className="page-header gallery-header">Some of Our Previous Work
            </h1>
            <div className="element-padding gallery-padding">
                <div className="page-section-container gallery-container">
                    {photos.map((photo, index) => (
                        <div className="page-grid-item" key={index}>
                            <img className="img page-grid-img gallery-image" src={photo} alt="" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Gallery;