import React, { useState } from 'react';

// Formspree
import { useForm } from '@formspree/react';

// Images & Icons
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faArrowUpFromGroundWater, faSwimmingPool, faShop, faToolbox, faHouse, faTrowel, faBucket, faHotTub, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jmpool3 from '../images/jmpool3.jpeg';
import jmpoolinstallation3 from '../images/jmpoolinstallation3.jpg';
import prevwork from '../images/prevwork.jpg';
import prevwork2 from '../images/prevwork2.jpg';
import prevwork3 from '../images/jmprevwork3.jpg';
import prevwork5 from '../images/jmprevwork5.jpg';
import prevwork6 from '../images/jmprevwork6.jpg';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons';
import LoadingSpinner from '../common/loading-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ReviewCard from '../common/ReviewCard';

const Home = () => {

    const [state, handleEmail] = useForm("mrgvavzg");
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            handleEmail(e);
            toast("Message succesfully sent!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            })

            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }
        catch {
            toast("An error occured...", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }
        setLoading(false);
    }

    return (
        <div className="page home">
            <ToastContainer />
            <div className="home__hero">
                <div className="home__hero-header-cont page-section">
                    <h1 className="home__hero-header">J & M Pool Installation & Repair</h1>
                    <h2 className="home__hero-subheader">
                        - Servicing Michigan Residents Since 2018 -
                    </h2>
                    <a className="btn home__hero-btn" href="/#contact">Contact Us Today</a>
                </div>
            </div>
            <div id="about" className="element-padding home__about page-section">
                <div className="home__about-item">
                    <h3 className="home__about-header">
                        We Are Swimming Pool & Outdoor Renovation Specialists That
                        Always Strive For Quality, Consistency, and Professionalism.
                    </h3>
                    <p className="home__about-body">
                        Looking for swimming pool and outdoor renovation specialists who can provide
                        high-quality, consistent, and professional service? Look no further than
                        J & M Pools. Our expertise in above-ground pools is unparalleled,
                        and we offer a wide range of services including custom deck building,
                        lighting installation, and simple repairs and installations. We take
                        the time to listen to your needs and preferences, and we are committed
                        to delivering exceptional results on every project, no matter the size
                        or complexity. Contact us today to learn more about our services and
                        how we can help you create the perfect outdoor oasis for your home
                        or business.
                    </p>
                    <a href="/#contact" className="btn home__about-btn">Contact Us Today</a>
                </div>
                <div className="home__about-item">
                    <img className="home__about-item__img" alt="Beauitful deck, patio, and pool." src={jmpool3} />
                </div>
            </div>
            <div id="services" className="home__services">
                <div className="page-section-container page-grid element-padding">
                    <div className="home__services-item page-section-item">
                        <ul className="home__services-item__list">
                            <li className="home__services-item__list-item">
                                <FontAwesomeIcon icon={faSwimmingPool} /> Above Ground & Semi-In-Ground Pool Installation
                            </li>
                            <li className="home__services-item__list-item">
                                <FontAwesomeIcon icon={faArrowUpFromGroundWater} /> Liner Changes
                            </li>
                            <li className="home__services-item__list-item">
                                <FontAwesomeIcon icon={faEye} /> Privacy Fence Installation
                            </li>
                            <li className="home__services-item__list-item">
                                <FontAwesomeIcon icon={faShop} /> Deck & Patio Renovations
                            </li>
                            <li className="home__services-item__list-item">
                                <FontAwesomeIcon icon={faToolbox} /> General Repairs: Pool, Patio, Deck, & Lawn
                            </li>
                            <li className="home__services-item__list-item">
                                <FontAwesomeIcon icon={faHouse} /> Flooring: Wooden, Cermaic, Tile
                            </li>
                            <li className="home__services-item__list-item">
                                <FontAwesomeIcon icon={faTrowel} /> Dry Wall, Siding, & Brick Repairs
                            </li>
                            <li className="home__services-item__list-item">
                                <FontAwesomeIcon icon={faBucket} /> In-Ground Epoxy Paint Pool Refurbish
                            </li>
                            <li className="home__services-item__list-item">
                                <FontAwesomeIcon icon={faHotTub} /> Hot Tub Removals & Deliveries
                            </li>
                            <li className="home__services-item__list-item">
                                <FontAwesomeIcon icon={faTrash} /> Junk Removals
                            </li>
                        </ul>
                    </div>
                    <div className="home__services-item page-section-item">
                        <h4 className="home__services-item__header page-section-header">
                            Our Services
                        </h4>
                        <p className="home__services-item__body">
                            J & M Pools is your one-stop-shop for all of your swimming pool needs.
                            Our comprehensive range of products and services includes above
                            ground and semi-in-ground pool installation, privacy fence
                            installation, deck and patio renovations, and more.
                        </p>
                        <p className="home__services-item__body">
                            Our team of experts is dedicated to delivering exceptional results
                            and customer satisfaction. We take pride in our workmanship and
                            attention to detail, and we believe in working closely with our
                            clients to ensure that every project we undertake meets their
                            unique needs and preferences. Contact us today to learn more
                            about our services and how we can help you create the perfect
                            outdoor living space for your home.
                        </p>
                        <a href="/#contact" className="btn home__services-item-btn">Contact Us Today</a>
                    </div>
                </div>
            </div>
            <div id="gallery" className="home__gallery page-section element-padding">
                <div className="home__gallery-white ">
                    <h5 className="home__gallery-header-main page-section-header">Some Of Our Previous Work</h5>
                    <div className="home__gallery-section home__gallery-section-wide">
                        <img
                            className="home__about-item__img home__gallery-img"
                            src={prevwork}
                            alt="Completed above-ground pool installation with blue lining."
                        />
                        <img
                            className="home__about-item__img home__gallery-img"
                            src={prevwork2}
                            alt="Completed semi-inground pool installation with excpetional work."
                        />
                        <img
                            className="home__about-item__img home__gallery-img"
                            src={jmpoolinstallation3}
                            alt="Completed semi-inground pool installation. with a beautiful deck and blue lining."
                        />
                        <img
                            className="home__about-item__img home__gallery-img"
                            src={prevwork3}
                            alt="Completed above-inground pool installation half filled with water."
                        />
                        <img
                            className="home__about-item__img home__gallery-img"
                            src={prevwork5}
                            alt="Completed above-ground pool installation in a nice backyard."
                        />
                        <img
                            className="home__about-item__img home__gallery-img"
                            src={prevwork6}
                            alt="In progress semi-inground pool installation."
                        />
                    </div>
                    <Link className="btn home__gallery-link"
                        to="/gallery"
                    >See More Of Our Work</Link>
                </div>
            </div>
            <div id="reviews" className="home__services">
                <div className="element-padding page-section">
                    <h2 className="reviews-header">
                        Customer Reviews
                    </h2>
                    <div className="page-section-container review-container">
                        <ReviewCard name="Ryna S." body="John and his team are world class at 
                        what they do; they got our Doughboy looking brand new and their prices are so fair..."
                            date="June 8, 2023" />
                        <ReviewCard name="Tammy M." body="Picked out a pool and one week later it was installed. Great job..."
                            date="July 23, 2022" />
                        <ReviewCard name="Danii M." body="I would recommend them to anyone they did a phenomenal 
                        job. They were very professional! I was nervous about the price because they seem to be a 
                        little bit more than other places but in the end I’m glad I went with them it was well worth it! "
                            date="March 2, 2022" />
                        <ReviewCard name="Susie S." body="John was awesome to work with. They 
                        had the pool installed in one day.  The price was fair and the pool looks great!"
                            date="July 31, 2022" />
                    </div>
                    <a className="btn home__services-item-btn"
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.facebook.com/people/J-M-Pools-LLC-Installations-and-Repairs/100089343932868/?sk=reviews"
                    >Leave a Review</a>
                    <p className="review-helper">
                        Leaving a review helps us improve our services and helps others find us. Thank you
                        in advance for your support!
                    </p>
                </div>
            </div>
            <div id="contact" className="element-padding contact page-section">
                <form
                    className="contact__form"
                    method="POST"
                    action="https://formspree.io/f/mrgvavzg"
                    onSubmit={(e) => {
                        handleSubmit(e);
                    }}
                >
                    <h6 className="contact__header">Contact Us Today</h6>
                    <input
                        className="contact__form-input"
                        type="text"
                        placeholder='Name'
                        id='name'
                        name="Name"
                    />
                    <input
                        className="contact__form-input"
                        type="email"
                        placeholder='Email'
                        id='email'
                        name="Email"
                    />
                    <textarea
                        className="contact__form-input"
                        rows="4"
                        placeholder='Message'
                        id='message'
                        name="Message"
                    />
                    <button className="btn contact__form-btn" type="submit">
                        <LoadingSpinner title="Send" loading={loading} />
                    </button>
                </form>
                <div className="contact__item">
                    <div className="contact__item-body">
                        <h6 className="contact__item-header">We value your feedback and would love to talk to you more about how we can serve you better.</h6>
                        <p className="contact__item-body">Feel free to reach out to us through our email, phone, or contact form, and we'll get back to you as soon as possible</p>
                    </div>
                    <div className="contact__item-body">
                        <h6 className="contact__item-header">Hours</h6>
                        <p className="contact__item-body">9:00am - 5:00pm: Monday - Friday</p>
                    </div>
                    <div className="contact__item-body">
                        <h6 className="contact__item-header"><FontAwesomeIcon icon={faCcVisa} /> We accept all major credit cards, including Visa, for payment. </h6>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;