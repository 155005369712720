import React, { useState } from 'react';

// Images / Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import JMLogo from '../images/JMLogo.png';
import { Link } from 'react-router-dom';

const Header = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);

    return (
        <div className="header">
            <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to="/">
                    <img className="navbar-brand__img" src={JMLogo} alt="J & M Pool Installation & Service" />
                    {/* <FontAwesomeIcon className="navbar-icon" icon={faPersonSwimming} /> */}
                </Link>
                <button onClick={() => setNavbarOpen(!navbarOpen)} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <FontAwesomeIcon className="toggler-icon" icon={navbarOpen ? faXmark : faBars}
                    />
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="/#about">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#services">Services</a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/gallery">Gallery</Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#reviews">Reviews</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default Header;