import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';


const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__list">
                <a className="footer__link" href="tel:7344109176"><FontAwesomeIcon icon={faPhone} /> (734) 410-9176</a>
                <a className="footer__link" href="https://www.facebook.com/profile.php?id=100089343932868" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebook} /> Facebook</a>
                <a className="footer__link" href="mailto:jmpools7169@gmail.com"><FontAwesomeIcon icon={faEnvelope} /> jmpools7169@gmail.com</a>
            </div>
        </div>
    );
}

export default Footer;