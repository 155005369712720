import React from 'react';
import blackLoading from '../images/blackLoading.gif';


const LoadingSpinner = ({ loading, title }) => {
    return (
        <div>
            {loading ? <img className="loading-spinner" src={blackLoading} alt="loading spinner" /> : title}
        </div>
    );
}

export default LoadingSpinner;