
import React from 'react'

const ReviewCard = ({ name, date, body }) => {
    return (
        <div className="review-card">
            <div className="review-card__header">
                <h4 className="review-card__header-name">{name}</h4>
                <p className="review-card__header-date">{date}</p>
            </div>
            <div className="review-card__body">
                {body}
            </div>
        </div>
    );
}

export default ReviewCard;